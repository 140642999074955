@use "@angular/material" as mat;

:root {
  /** Enstack Colors **/

  // Enstack Blue
  --enstack-color-blue-main: #0099e0;
  --enstack-color-blue-100: #345678;
  --enstack-color-blue-75: #096999;
  --enstack-color-blue-50: #44ccff;
  --enstack-color-blue-25: #9fe9fe;

  /** For Tailwind CSS **/
  --enstack-color-blue-main-rgb: 0 153 224;
  --enstack-color-blue-100-rgb: 52 86 120;
  --enstack-color-blue-75-rgb: 9 105 153;
  --enstack-color-blue-50-rgb: 68 204 255;
  --enstack-color-blue-25-rgb: 159 233 254;

  // Royal Blue
  --enstack-color-royalBlue-main: #000aaa;
  --enstack-color-royalBlue-100: #000045;
  --enstack-color-royalBlue-75: #000066;
  --enstack-color-royalBlue-50: #000ddd;
  --enstack-color-royalBlue-25: #0044ff;

  /** For Tailwind CSS **/
  --enstack-color-royalBlue-main-rgb: 0 10 170;
  --enstack-color-royalBlue-100-rgb: 0 0 69;
  --enstack-color-royalBlue-75-rgb: 0 0 102;
  --enstack-color-royalBlue-50-rgb: 0 13 221;
  --enstack-color-royalBlue-25-rgb: 0 68 255;

  // Purple
  --enstack-color-purple-main: #b333cc;
  --enstack-color-purple-100: #660090;
  --enstack-color-purple-75: #880aaa;
  --enstack-color-purple-50: #cc6eee;
  --enstack-color-purple-25: #ddaaff;

  /** For Tailwind CSS **/
  --enstack-color-purple-main-rgb: 179 51 204;
  --enstack-color-purple-100-rgb: 102 0 144;
  --enstack-color-purple-75-rgb: 136 10 170;
  --enstack-color-purple-50-rgb: 204 110 238;
  --enstack-color-purple-25-rgb: 221 170 255;

  // Flamingo
  --enstack-color-flamingo-main: #f22c66;
  --enstack-color-flamingo-100: #770022;
  --enstack-color-flamingo-75: #bb1144;
  --enstack-color-flamingo-50: #f77aa3;
  --enstack-color-flamingo-25: #ffccdd;

  /** For Tailwind CSS **/
  --enstack-color-flamingo-main-rgb: 242 44 102;
  --enstack-color-flamingo-100-rgb: 119 0 34;
  --enstack-color-flamingo-75-rgb: 187 17 68;
  --enstack-color-flamingo-50-rgb: 247 122 163;
  --enstack-color-flamingo-25-rgb: 255 204 221;

  // Pumpkin
  --enstack-color-pumpkin-main: #f39f44;
  --enstack-color-pumpkin-100: #e77000;
  --enstack-color-pumpkin-75: #ee8800;
  --enstack-color-pumpkin-50: #f9b644;
  --enstack-color-pumpkin-25: #ffc944;

  /** For Tailwind CSS **/
  --enstack-color-pumpkin-main-rgb: 243 159 68;
  --enstack-color-pumpkin-100-rgb: 231 112 0;
  --enstack-color-pumpkin-75-rgb: 238 136 0;
  --enstack-color-pumpkin-50-rgb: 249 182 68;
  --enstack-color-pumpkin-25-rgb: 255 201 68;

  // Green
  --enstack-color-green-main: #0fa960;
  --enstack-color-green-100: #096030;
  --enstack-color-green-75: #0c8448;
  --enstack-color-green-50: #06d080;
  --enstack-color-green-25: #06e686;

  /** For Tailwind CSS **/
  --enstack-color-green-main-rgb: 15 169 96;
  --enstack-color-green-100-rgb: 9 96 48;
  --enstack-color-green-75-rgb: 12 132 72;
  --enstack-color-green-50-rgb: 6 208 128;
  --enstack-color-green-25-rgb: 6 230 134;

  // Onyx
  --enstack-color-onyx-0: #fff;
  --enstack-color-onyx-10: #e9e9e9;
  --enstack-color-onyx-20: #d4d5d5;
  --enstack-color-onyx-40: #abadad;
  --enstack-color-onyx-60: #808585;
  --enstack-color-onyx-80: #555a5a;
  --enstack-color-onyx-100: #2c3333;

  /** For Tailwind CSS **/
  --enstack-color-onyx-0-rgb: 255 255 255;
  --enstack-color-onyx-10-rgb: 233 233 233;
  --enstack-color-onyx-20-rgb: 212 213 213;
  --enstack-color-onyx-40-rgb: 171 173 173;
  --enstack-color-onyx-60-rgb: 128 133 133;
  --enstack-color-onyx-80-rgb: 85 90 90;
  --enstack-color-onyx-100-rgb: 44 51 51;

  // Status Colors
  --enstack-color-status-green: #0c8448;
  --enstack-color-status-yellow: #ffcc00;
  --enstack-color-status-red: #ff0000;

  /** For Tailwind CSS **/
  --enstack-color-status-green-rgb: 12 132 72;
  --enstack-color-status-yellow-rgb: 255 204 0;
  --enstack-color-status-red-rgb: 255 0 0;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@import "./colors.scss";

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  margin-top: 20px;
  background-color: transparent !important;
  //   height: 40px !important;
  margin-left: -10px;
  border: 0;
  width: calc(100vw - 80px);
}
@import "~@ng-select/ng-select/themes/default.theme.css";

/**
* Custom Styles for Reactive Ng Select Field
*/
.ng-option {
  border-bottom: 1px solid var(--enstack-color-onyx-40);
  .ng-option-label {
    font-family: Inter;
    font-size: 1.125rem !important;
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
  }
}

.ng-option:last-child {
  border-bottom: none !important;
}

.ng-dropdown-panel {
  max-height: 200px; /* Set your desired max height */
  overflow-y: auto;
  // z-index: 1000; /* Ensure the dropdown is above other elements */
}

  // max-height: 200px; /* Set your desired max height */
  // overflow-y: auto;
  // z-index: 1000; /* Ensure the dropdown is above other elements */
@layer base {
    body {
        @apply tw-font-inter
    }
}

@layer components {
    .btn {
      font-weight: 600;
      height: 48px; // Don't use padding since the height will be uneven
      text-align: center;
      border-radius: 1px;
      box-sizing: border-box;
      @apply tw-font-inter
    }
  
    .btn-primary {
      @apply btn tw-bg-enstack-blue-main tw-text-white;
    }
  
    .btn-secondary {
      border: 1px solid;
      @apply btn tw-text-enstack-blue-main tw-border-enstack-blue-main;
    }
  
    .btn-small {
      @apply tw-px-2 tw-h-5 tw-uppercase tw-font-inter tw-font-semibold tw-text-[10px] tw-text-center tw-text-white tw-bg-enstack-blue-main;
    }

    .error-message {
      margin-top: 0.25rem;
      font-size: 14px;
      color: var(--enstack-color-status-red);
    }
  }
